<template>
    <section class="exemption-application padding_10_15">
        <div class="form-search-container bg-white">
            <el-form :model="formSearch" inline size="small">

                <el-form-item>
                    <el-input v-model="formSearch.keyword" placeholder="请输入租户名称" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" @click="reductionExport" icon="el-icon-download">全部导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table
                class="bg-white"
                ref="rentalTableRef"
                :dataRequest="getRefundTableData"
                :columns="tableColumn"
                :height="810">
            <template slot="empty">
                <el-empty></el-empty>
            </template>
            <el-table-column slot="toolbar" label="操作" align="center" width="230px">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button size="mini" style="padding: 5px 10px !important;" v-if="row.statusCurrent!==36" type="primary" @click="openDialog(row,'shen')">审核</el-button>
                        <el-button size="mini" style="padding: 5px 10px !important;" v-if="row.statusCurrent===36" @click="openDialog(row,'cha')">查看</el-button>
                        <el-button size="mini" style="padding: 5px 10px !important;" v-if="row.statusCurrent===36" @click="()=>{dialogVisible=true;applyData=row}">打印表单</el-button>
                        <el-button size="mini" style="padding: 5px 10px !important;" type="danger" @click="removeData(row)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <dialog-exemption-application ref="dialogExemptionApplication" @handleSearch="handleSearch"></dialog-exemption-application>

        <r-e-dialog title="减租表单打印" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                    top="10vh" width="600px" @click-submit="before" @click-cancel="()=>{dialogVisible=false}" @close="()=>{dialogVisible=false}"
        >
            <el-form ref="formPublish" label-width="125px" size="small" :model="applyData" :rules="rules">
                <div class="flex">
                    <el-form-item label="申请人" prop="">
                        <el-input type="text" v-model="applyData.applicantName" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="证件号码" prop="">
                        <el-input type="text" v-model="applyData.businessLicense" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="承租地址" prop="">
                        <el-input type="text" v-model="applyData.leasorAddress" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>

                <div class="flex">
                    <el-form-item label="联系电话" prop="">
                        <el-input type="text" v-model="applyData.applicantPhone" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="合同租期" prop="">
                        <el-input type="text" v-model="applyData.rentDate" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="2022年已缴租金" prop="">
                        <el-input type="text" v-model="applyData.yearAmount" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="可减免金额" prop="">
                        <el-input type="text" v-model="applyData.actualAmount" style="width: 400px;" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
    import {getRefundListApi, reductionExport, before, print, deleteData} from "@/api/exemption-application";
    import { tableColumn } from "./data.js";
    import {downloadByData} from "@/utils/hooks/download";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";

    export default {
        name: "exemption-application",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: ""
                },
                applyData:{},
                dialogVisible:false,
                rules:{}
            };
        },
        components:{dialogExemptionApplication:()=>import("./components/dialog-exemption-application.vue")},
        methods: {
            getRefundTableData(params) {
                // 获取租户列表
                return getRefundListApi({ ...params, ...this.formSearch });
            },
            handleSearch(isSearch=true) {
                // 搜索
                this.$refs.rentalTableRef.getTableData(isSearch);
            },
            handleReset() {
                // 重置
                this.formSearch.keyword = "";
                this.handleSearch();
            },
            reductionExport(){
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                reductionExport().then(res=>{
                    downloadByData({data: res, filename: "减租申请.xlsx"});
                    loading.close();
                }).catch(err=>{
                    loading.close();
                });
            },
            openDialog(data,type){
                this.$refs["dialogExemptionApplication"].openDialog(data,type);
                console.log(data);
            },
            before(){
                let {uuid, actualAmount, applicantPhone, businessLicense, leasorName, leasorAddress, rentDate, yearAmount}=this.applyData;
                let data={uuid, actualAmount, applicantPhone, businessLicense, leasorName, leasorAddress, rentDate, yearAmount};
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                before(data).then(async res=>{
                    let resd= await print(uuid);
                    if(resd){
                        const binaryData = [];
                        binaryData.push(resd);
                        let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
                        let tempwindow = window.open("",'_blank');
                        tempwindow.location =pdfUrl;
                    }
                    loading.close();
                    this.dialogVisible=false;
                }).catch(err=>{
                    loading.close();
                });
            },
            removeData(data){
                let {uuid} = data;
                this.$confirm('此操作永久删除此数据,是否继续?', '注意', { type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    deleteData(uuid).then(res=>{
                        MessageSuccess('删除成功');
                        this.handleSearch();
                        loading.close();
                    }).catch(err=>{
                        MessageError('删除失败');
                        loading.close();
                    });
                }).catch(() => {});
            }
        }
    };
</script>

<style lang="scss" scoped>
    .exemption-application {

        .form-search-container {
            padding: VH(20px) VW(15px) 0;
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                    width: VW(250px);
                }
            }
        }
    }
</style>