import request from "@http";

// 获取减租申请列表
export function getRefundListApi(params) {
    return request({
        method: "get",
        url: `/api/shop/second/rentreduction`,
        params,
    });
}
// 导出减租申请
export function reductionExport() {
    return request({
        method: "get",
        url: `/api/shop/second/reduction/export`,
        responseType: 'blob',
    });
}
// 获取日志
export function getLogList(uuid) {
    return request({
        method: "get",
        url: `/api/shop/second/reduction/${uuid}/log`
    });
}

//打印表单
export function before(data) {
    return request({
        method: "PUT",
        url: `/api/shop/second/${data.uuid}/before`,
        data
    });
}
//提交审批结论
export function approval(data) {
    return request({
        method: "PUT",
        url: `/api/shop/second/${data.uuid}/approval`,
        data
    });
}
//提交审批结论
export function deleteData(uuid) {
    return request({
        method: "GET",
        url: `/api/shop/second/${uuid}/delete`,
    });
}

export function print(uuid) {
    return request({
        method: "get",
        url: `/api/shop/second/${uuid}/print`,
        responseType: 'blob',
    });
}

