import {timeFormat} from "@custom/index";
//减租申请
export const tableColumn = [
    {
        type: "index",
        label: "#",
        width: 50
    },
    {
        prop: "createTime",
        label: "申请时间",
        align: "center",
        render: (h, {row}) => {
            return timeFormat(new Date(row.createTime),'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        prop: "applicantName",
        label: "承租方",
        align: "center"
    },
    {
        prop: "leasorAddress",
        label: "租赁地址",
        align: "center"
    },
    {
        prop: "applicantName",
        label: "联系姓名",
        align: "center"
    },
    {
        prop: "applicantPhone",
        label: "联系方式",
        align: "center"
    },
    {
        prop: "rentAmount",
        label: "减免金额",
        align: "center"
    },
    {
        prop: "way",
        label: "减免方式",
        align: "center"
    },
    {
        prop: "statusCurrent",
        label: "审批状态",
        align: "center",
        render: (h, { row }) => {
            let x="";
            switch (row.statusCurrent)
            {
                case 0:x="预审资料";
                    break;
                case 10:x="预审资料";
                    break;
                case 11:x="重新提交";
                    break;
                case 12:x="预审不通过";
                    break;
                case 16:x="预审通过";
                    break;
                case 20:x="提交纸质资料";
                    break;
                case 21:x="纸质资料重新提交";
                    break;
                case 22:x="纸质资料不通过";
                    break;
                case 26:x="纸质资料通过";
                    break;
                case 36:x="打印";
                    break;
                case 40:x="打印完成";
                    break;
            }
            return x;
        }

    }
];
